import React, { useEffect, useState } from "react"
import ProgressBar from "@ramonak/react-progress-bar"
import { getReservations } from "../../middleware/apiConnector";

const ProgressBarMint = () => {
  const [total, setTotal] = useState(0);
  const [reserved, setReserved] = useState(0);

  useEffect(async () => {
    const { totalAmount, currentAmountReserved } = await getReservations();
    setTotal(totalAmount);
    setReserved(currentAmountReserved);
  })

  return (
    <>
      <ProgressBar
        completed={`${reserved}`}
        customLabel={`${reserved}/${total}`}
        maxCompleted={total}
        bgColor="#36355C"
        baseBgColor="#ffffff"
        height="12px"
        borderRadius="20px"
        width="600px"
        className="progressbarroo desktop"
      />
      <ProgressBar
        completed={`${reserved}`}
        customLabel={`${reserved}/${total}`}
        maxCompleted={total}
        bgColor="#36355C"
        baseBgColor="#ffffff"
        height="12px"
        borderRadius="20px"
        width="60%"
        className="progressbarroo mobile"
      /></>
  )
}

export default ProgressBarMint;