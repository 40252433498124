import * as React from "react";
import { Scrollchor, easeOutQuad } from "react-scrollchor";
import "./index.scss";

import logo from "../../images/logo-cult-of-pigeons@2x.png";
import pigeonlook from "../../images/pigeon-look@2x.png";
import pigeonglare from "../../images/pigeon-look-desktop@2x.png";
import logoDiscord from "../../images/logo-discord.svg";
import logoTwitter from "../../images/logo-twitter.svg";
import logoInstagram from "../../images/logo-instagram.svg";
import bgo from "../../images/bg-o.svg";

const BurgerMenu = ({ goToAnchor }) => {
  return (
    <div className="burgermenu">
      <img className="glowybackground" src={bgo} alt="" />
      <div className="grid grid-cols-12 gap-2">
        <div className="lg:col-start-2 lg:col-span-4">
          <img className="logo" src={logo} alt="Cult of Pigeons Logo" />
        </div>
      </div>
      <div className="content">
        <div className="menuitems">
          <Scrollchor
            beforeAnimate={goToAnchor}
            animate={{ duration: 2000, easing: easeOutQuad }}
            to="#cultofpigeons"
          >
            Cult of Pigeons
          </Scrollchor>
          <Scrollchor
            beforeAnimate={goToAnchor}
            animate={{ duration: 2000, easing: easeOutQuad }}
            to="#samples"
          >
            Samples
          </Scrollchor>
          <Scrollchor
            beforeAnimate={goToAnchor}
            animate={{ duration: 2000, easing: easeOutQuad }}
            to="#traits"
          >
            Traits &amp; Rarity
          </Scrollchor>
          <Scrollchor
            beforeAnimate={goToAnchor}
            animate={{ duration: 2000, easing: easeOutQuad }}
            to="#roadmap"
          >
            Roadmap
          </Scrollchor>
          <Scrollchor
            beforeAnimate={goToAnchor}
            animate={{ duration: 2000, easing: easeOutQuad }}
            to="#bonus"
          >
            Bonus
          </Scrollchor>
          <Scrollchor
            beforeAnimate={goToAnchor}
            animate={{ duration: 2000, easing: easeOutQuad }}
            to="#faq"
          >
            FAQ
          </Scrollchor>
          <Scrollchor
            beforeAnimate={goToAnchor}
            animate={{ duration: 2000, easing: easeOutQuad }}
            to="#team"
          >
            Team
          </Scrollchor>
        </div>
        <img
          className="peekypigeon"
          src={pigeonlook}
          alt="Watching... waiting..."
        />
        <img
          className="glaringpigeon"
          src={pigeonglare}
          alt="Watching... waiting..."
        />
        <div className="socials">
          <a href="https://discord.gg/w8Ef3XB5tm">
            <img src={logoDiscord} alt="Discord Logo" />
          </a>
          <a href="https://twitter.com/pigeonscultnft">
            <img src={logoTwitter} alt="Twitter Logo" />
          </a>
          <a href="https://www.instagram.com/pigeonscultnft/">
            <img src={logoInstagram} alt="Instagram Logo" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default BurgerMenu;
