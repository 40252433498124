import * as React from "react";
import "./index.scss";

const Roadmap = ({ children }) => {
  const RoadMapItem = ({ type, number, title, text }) => {
    const positionClass = type === "left" ? "lg:col-start-3" : "lg:col-start-4";

    return (
      <>
        <div className="dots col-span-8 col-start-3">
          <div className="dot" />
          <div className="dot" />
          <div className="dot" />
        </div>
        <div
          className={`roadmap-item col-start-2 col-span-10 ${positionClass} lg:col-span-7 type-${type}`}
        >
          <div className="number">
            <span>{number}</span>
          </div>
          <div className="roadmap-content">
            <h4>{title}</h4>
            <span>{text}</span>
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="roadmap-container grid grid-cols-12 gap-2" id="roadmap">
      <div className="title-wrapper col-start-3 col-span-8">
        <h2>From here to...</h2>
      </div>
      <RoadMapItem
        type="right"
        number="10%"
        title="Done"
        text="Concepting. Art and finding out what evil plans The Cult has in store for us all..."
      />
      <RoadMapItem
        type="left"
        number="40%"
        title="Done"
        text="Finalizing art, setting up the Cult's very own website and preparing Cardano blockchain tech."
      />
      <RoadMapItem
        type="right"
        number="60%"
        title="Done"
        text="Releasing all traits and their rarities for you to see. This will not be good, it will be awesome!"
      />
      <RoadMapItem
        type="left"
        number="65%"
        title="Done"
        text="Preparing our social channels. Every Cult needs a way to contact other Cultists, right?"
      />
      <RoadMapItem
        type="right"
        number="70%"
        title="Done"
        text="Preparing and vigorously testing our release flow. One does not simply revolt against all humans without some proper planning, not even when you're a Cult of millions of pigeons."
      />
      <RoadMapItem
        type="left"
        number="80%"
        title="Public Mint: Now LIVE!"
        text="The Landing of the Cult on Cardano. We will start the mint of this first season of Pigeons right here on this website, at 18 March 2022, 23:00 CET"
      />
      <RoadMapItem
        type="right"
        number="90%"
        title="Airdrop Cult of Pigeons Spidey"
        text="Once we're sold out, we will airdrop 1 unique Cult of Pigeons Spidey to one of our holders. But remember, with great power comes great... madness and evil, this is The Cult we're talking about here people!"
      />
      <RoadMapItem
        type="left"
        number="90%"
        title="Airdrop Token of Gratitude"
        text="To show our love for each and everyone of you looking to join our Cult and mint one of our deliciously dastardly doves, we've prepared a Token of Gratitude NFT which will be airdropped to all holders AND the unlucky ones who did not manage to get a pigeon during mint. Can you feel the love people?"
      />
      <RoadMapItem
        type="right"
        number="100%"
        title="Prepare the next season for the Cult"
        text="We have decided to definitely release a second season of our Cult. More traits, new friends, and the next addition to our sattelite collectors edition superhero set. You are not prepared."
      />
    </div>
  );
};

export default Roadmap;
