import * as React from "react";
import "./index.scss";

import pigeon1 from "../../images/pigeongallery/pig01.png";
import pigeon2 from "../../images/pigeongallery/pig02.png";
import pigeon3 from "../../images/pigeongallery/pig03.png";
import pigeon4 from "../../images/pigeongallery/pig04.png";
import pigeon5 from "../../images/pigeongallery/pig05.png";
import pigeon6 from "../../images/pigeongallery/pig06.png";
import pigeon7 from "../../images/pigeongallery/pig07.png";
import pigeon8 from "../../images/pigeongallery/pig08.png";

const ImageGrid = ({ children }) => {
  return (
    <div id="samples">
      <div className="imageGrid grid grid grid-cols-16 grid-rows-16">
        <div className="bgpurple row-start-1 row-span-4 col-start-1 col-span-4"></div>
        <div className="bgpurple col-start-1 col-span-2 row-start-5 row-span-2"></div>
        <div className="col-start-1 col-span-4 row-start-7 row-span-4 bg-yellow-600">
          <img src={pigeon1} alt="" />
        </div>
        <div className="bgpurple col-start-3 col-span-2 row-start-5 row-span-2 ">
          <img className="rounded-tl" src={pigeon2} alt="" />
        </div>
        <div className="col-start-4 col-span-1 row-start-11 row-span-1">
          <img className="rounded-bl" src={pigeon3} alt="" />
        </div>
        <div className="col-start-5 col-span-8 row-start-1 row-span-8 rounded-br relative">
          <div className="bgpurple coverboi"></div>
          <img className="rounded-tl absolute" src={pigeon4} alt="" />
        </div>
        <div className="col-start-5 col-span-4 row-start-9 row-span-4">
          <img className="rounded-br" src={pigeon5} alt="" />
        </div>
        <div className="col-start-9 col-span-2 row-start-9 row-span-2">
          <img className="rounded-br" src={pigeon6} alt="" />
        </div>
        <div className="col-start-13 col-span-4 row-start-1 row-span-4">
          <img src={pigeon7} alt="" />
        </div>
        <div className="col-start-13 col-span-2 row-start-5 row-span-2">
          <img className="rounded-br" src={pigeon8} alt="" />
        </div>
      </div>
      <div className="mobileImageGrid">
        <img className="w-2/3 rounded-tr" src={pigeon2} alt="" />
        <img className="w-1/3 align-bottom" src={pigeon8} alt="" />
        <img className="w-full" src={pigeon7} alt="" />
        <img className="w-full" src={pigeon4} alt="" />
        <img className="w-1/2" src={pigeon3} alt="" />
        <img className="w-1/2" src={pigeon1} alt="" />
        <img className="w-1/3 align-top" src={pigeon6} alt="" />
        <img className="w-2/3 rounded-bl" src={pigeon5} alt="" />
      </div>
    </div>
  );
};

export default ImageGrid;
