import React, { useState } from "react";
import Markdown from "markdown-to-jsx";

import "./index.scss";

import bone from "../../images/Bone@2x.png";
import plus from "../../images/plus.svg";
import minus from "../../images/minus.svg";

const FaqItem = ({ title, description }) => {
  const [open, setOpen] = useState(false);

  const toggle = () => {
    setOpen(!open);
  };

  return (
    <div className={`${open ? "open" : ""} faq-item`} onClick={() => toggle()}>
      <h4>{title}</h4>
      <Markdown options={{ forceBlock: true }}>{description}</Markdown>
      <img src={open ? minus : plus} alt={`${open ? "Open" : "Closed"} icon`} />
    </div>
  );
};

const Faq = ({ children }) => {
  return (
    <div className="wrapper mx-auto">
      <div className="faq grid grid-cols-12 gap-2" id="faq">
        <div className="bone-wrapper col-start-3 col-span-8">
          <img src={bone} alt="A juicy pigeon bone" className="bone" />
        </div>
        <div className="title-wrapper col-start-2 col-span-10 lg:col-start-3 lg:col-span-8">
          <h2>Everything you need to know</h2>
        </div>
        <div className="faq-container col-start-2 col-span-10 lg:col-start-3 lg:col-span-8">
          <FaqItem
            title="The Cult of Pigeons, who are they?"
            description="**ALL** pigeons in every city around the world are already devoted cultists, ready to commence their World Domination Plan. Your only chance to join with the Cult and be part of this new world order is by purchasing a cultist Pigeon NFT in our upcoming release. When a great battle comes, it's best to be on the winning side. Choose wisely..."
          />
          <FaqItem
            title="How can I buy a pigeon?"
            description="At 18 March 2022 on 23:00 CET the Cult takes flight. We've integrated our website seemlessly with the Nami and ccvault wallets in the Chrome browser. Make sure you have one of those wallets installed with enough ADA ready to go and you'll be in for a very smooth mint!"
          />
          <FaqItem
            title="How much does a Cult of Pigeons NFT cost?"
            description="One Pigeon NFT will be sold for **50 ADA** + transaction costs."
          />
          <FaqItem
            title="How can I create a Cardano wallet?"
            description="For our mint we only support the Nami and ccvault wallets in the Chrome browser. Please follow the official instructions on the Nami and ccvault websites on how to install."
          />
          <FaqItem
            title="Why on Cardano?"
            description="First and foremost, we believe in the platform. As longtime Cardano holders ourselves, we want to give back to the Cardano community by releasing a NFT collection on Cardano, done right. Crazy good looking art, built by hand by our master illustrator. Technologically thoroughly thought out (try saying that 3 times in a row) and built to industry standards. A well tested and smooth minting process, directly on Cardano. I mean, it's almost as if we truly believe these Pigeons will become our New World Order and we want to be in their good books when the time comes..."
          />
          <FaqItem
            title="Are there royalties?"
            description="We will not enforce any royalties during mint. There will be some royalties on secondary marketplaces such as jpg.store (4% for every sold pigeon). Any royalties will always go directly into our community wallet, to help us build new seasons and features for the Cult.<br/><br/> This means that our public mint is still completely royalty free and every Pigeon you mint and own will be completely yours, without any built in royalties. You are owning a Cardano NFT, a native object on the Cardano blockchain, which we've chosen not to wrap in a smart contract. Your Pigeons are yours, your own, your prrrecious."
          />
          <FaqItem
            title="Is there a limit?"
            description="Per transaction you can choose to buy anywhere between 1 to 10 Pigeons per transaction. Our initiation flow during mint shall guide you through the steps to becoming a devoted cultist yourself."
          />
          <FaqItem
            title="How can I tell how rare my Pigeon is?"
            description="Traits and rarities are published in the traits and rarity section, for everyone to see. We've set up a rarity system containing 8 categories and 150 traits. Before mint, we will publish what rarity category a trait is: common, uncommon, rare, epic or legendary. The exact percentages will be published right after minting. A good Cult with dastardly plans can never reveal the entire plan beforehand, now can we?"
          />
          <FaqItem
            title="Will there be a next chapter?"
            description="Yes, most definitely! We are preparing a second season already with our concepting and design artist. Details will follow, but rest assured... it will be mental!"
          />
        </div>
      </div>
    </div>
  );
};

export default Faq;
