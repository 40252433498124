import * as React from "react";
import "./index.scss";

import spidey from "../../images/spidey-boss.png";

const Spidey = ({ children }) => {
  return (
    <div className="spidey grid grid-cols-12 gap-2" id="bonus">
      <div className="title-wrapper col-start-1 col-span-12 lg:col-start-3 lg:col-span-8">
        <h2>Airdrop Bonus</h2>
      </div>
      <div className="content-wrapper col-start-2 col-span-10 lg:col-start-3 lg:col-span-8">
        <img src={spidey} alt="Spiderpigeon, spiderpigeon..." />
        <div className="spidey-content">
          <p>
            When all Pigeons are sold ONE lucky winner will receive the FREE
            Spidey Pigeon bonus airdrop.
          </p>
          <div className="moltenpigeonmilk" />
        </div>
      </div>
    </div>
  );
};

export default Spidey;
