import * as React from "react";

import "./index.scss";

import { Button } from "..";
import ProgressBarFollowers from "../ProgressBarFollowers";
import Wallet from "../Wallet";

import { features } from "../../config/features";



import feathertop from "../../images/feather-02.png";
import featherleft from "../../images/feather-01.png";
import featherright from "../../images/feather-03.png";

// import namiInstructions from "../../images/install-nami-wallet.png";
// import ccvaultInstructions from "../../images/install-ccvaultio-wallet.png";

import ProgressBarMint from "../ProgressBarMint";

const Intro = ({ handleConnect, walletConnected, protocolParams, selectedWallet, wallet, resetWalletConnection }) => {
  return (
    <div className="intro grid grid-cols-12 gap-2" id="cultofpigeons">
      <div className="promo col-start-2 col-span-10 lg:col-start-3 lg:col-span-8">
        {features.followerBar && (<ProgressBarFollowers />)}
        {features.mintBar && (<ProgressBarMint />)}
        {features.mintButton &&
          <Wallet
            handleConnect={handleConnect}
            walletConnected={walletConnected}
            protocolParams={protocolParams}
            selectedWallet={selectedWallet}
            wallet={wallet}
            resetWalletConnection={resetWalletConnection}
          />}
        {/* <p>
          {`Mint is LIVE! Just hit the "Connect Wallet" button at the top to connect with your Nami or ccvault Chrome wallets
           to enter the mint process. If you need help installing either wallets, we have some instructions for `}
           <a href={namiInstructions} target="_blank">Nami</a>{` and `}<a href={ccvaultInstructions} target="_blank">ccvault</a>{`.
           For scoops, promotions and collabs, our Discord is the place to be. 
           Looking forward to greeting you all after initiation into the Cult of Pigeons. Coo-coo!`}
        </p> */}
        <p>
        {`Live minting for season 1 of the Cult of Pigeons is currently closed. Keep an eye on this space for updates and our mint re-open date.
          Looking forward to welcoming you all into the Cult of Pigeons. Coo-coo!`}
        </p>
        <a href="https://discord.gg/w8Ef3XB5tm"><Button className="roadmap desktop">Discord</Button></a>
        <a href="https://discord.gg/w8Ef3XB5tm"><Button className="roadmap mobile">Discord</Button></a>
      </div>
      <div className="feathers col-start-2 col-span-10 lg:col-start-3 lg:col-span-8">
        <h1>
          <img className="fleft" src={featherleft} alt="" />
          <img className="ftop" src={feathertop} alt="" />
          <img className="fright" src={featherright} alt="" />
          Cult of Pigeons
        </h1>
      </div>
      <div className="col-start-2 col-span-10 lg:col-start-3 lg:col-span-8">
        <p>
          For generations, pigeons have lived alongside us. Ever the symbol of
          peace and prosperity, they have made their homes in our cities, our
          parks and our buildings. Being harmless and peaceful creatures,
          mankind has had nothing to fear from our friendly, feathered friends.
        </p>
        <p>Until now.</p>
        <p>
          For the pigeons we thought friendly have been secretly planning
          something dark. They have been watching us, infiltrating our human
          society all around the world. They have formed a secret organization,
          plotting their rise against us humans. Planning to overthrow us and
          become the new masters of our world, a kind of apex predator unlike
          any before. An organization more global than the world has ever seen,
          with members devoted to their cause. A cult of sorts.
        </p>
        <p>The Cult of Pigeons.</p>
        <p>
          {`The Cult has landed on the Cardano blockchain in the form of a
          collection of 10,000 unique Pigeon NFT's. With traits expertly
          illustrated to an insane level of detail, the Cult has vowed to drop a
          collection of the highest quality. Details to feast your eyes on.
          Traits as mad as our pigeon cultists. Cardano, are you ready?`}
        </p>
      </div>
    </div>
  );
};

export default Intro;
