import { features } from "../config/features";


const endpoint = features.isProd ? 'https://x0wpbnqkk0.execute-api.eu-west-1.amazonaws.com' : 'https://bnxxzb8p62.execute-api.eu-west-1.amazonaws.com';

const getCall = async (path) => {
  const response = await fetch(`${endpoint}${path}`, {
    headers: { "Content-Type": "application/json", 'cache-control': 'no-cache' },
    method: 'GET'
  });

  const result = response.status === 200 ? response.json() : response

  return result;
}

const getWalletParams = async () => {
  const result = await getCall('/params/wallet')

  return result;
}

const getWalletParamsPresale = async (walletAddress) => {
  const result = await getCall(`/params/wallet/${walletAddress}`)

  return result;
}

const getProtocolParams = async () => {
  const result = await getCall('/params/protocol')

  return result;
}

const getReservations = async () => {
  const result = await getCall('/reservations/amount')

  return result;
}

const postReservation = async (amountOfTokens, txHash) => {
  const response = await fetch(`${endpoint}/addresses/reservations`, {
    headers: { "Content-Type": "application/json", 'cache-control': 'no-cache' },
    method: 'POST',
    body: JSON.stringify({
      "transactionId": txHash,
      "amount": amountOfTokens
    })
  });

  // console.log('postReservation', response);
  // console.log("API:  -> response", response)

  return response;
}

export { getWalletParams, getWalletParamsPresale, getProtocolParams, getReservations, postReservation }
