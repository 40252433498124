import React, { useState, useEffect } from "react";
import { toast } from 'react-toastify';
import Countdown from 'react-countdown';
import { doTransaction } from "../../middleware/walletConnector";
import { getWalletParams, getWalletParamsPresale } from "../../middleware/apiConnector";
import PigeonNumber from "../PigeonNumber";
import { features } from "../../config/features";
import "./index.scss";

import Button from "../Button";
import { MintContainer } from "..";

import checkumarku from "../../images/ic-yes.svg";

const MINT_STATUS = {
  START: 'start',
  NOT_ON_WHITELIST: 'not_on_whitelist',
  TRANSACTION_STARTED: 'transaction_started',
  TRANSACTION_SUCCESS: 'transaction_success',
  TRANSACTION_FAILED: 'transaction_failed'
}

const capitalize = s => (s && s[0].toUpperCase() + s.slice(1)) || "";

const Wallet = ({ walletConnected, selectedWallet, wallet }) => {
  const [walletParams, setWalletParams] = useState();
  const [status, setStatus] = useState();
  const [walletAddress, setWalletAddress] = useState();

  useEffect(async () => {
    if (walletConnected && wallet && wallet.isEnabled) {
      try {
        const walletAddr = await wallet.getAddress();
        setWalletAddress(walletAddr);

        let params;
        if (features.isPresale) {

          params = await getWalletParamsPresale(walletAddr);
        } else {
          params = await getWalletParams();
        }

        // When the call is successful (no catch triggered) but it's a 404, that means your address is not whitelisted
        if (params.status === 404) {
          setStatus(MINT_STATUS.NOT_ON_WHITELIST)
        } else {
          setStatus(MINT_STATUS.START)
        }

        setWalletParams(params);
      } catch (error) {
        setStatus(MINT_STATUS.TRANSACTION_FAILED)
      }
    }
  }, [walletConnected, wallet])

  const mintAnother = () => {
    setStatus(MINT_STATUS.START);
  }

  const handleTransaction = async (data) => {
    window.scrollTo(0, 0);
    setStatus(MINT_STATUS.TRANSACTION_STARTED)

    const response = await doTransaction({
      ...data,
      walletAddress: walletParams.walletAddress,
      wallet,
    });

    if (response.status === 'success') {
      toast(`AMAZING! You just joined the Cult of Pigeons! Sit tight, your order is on the way and will be delivered in your ${capitalize(selectedWallet)} wallet automatically!`);
      window.scrollTo(0, 0);
      setStatus(MINT_STATUS.TRANSACTION_SUCCESS)
    } else {
      toast(`Uh-oh! Something went wrong! This is what your ${capitalize(selectedWallet)} wallet tells us: ${response.message} `);

      // We cannot do this. When a user tries a transaction without enough funds we also get 'undefined' from the wallets.
      // Resetting the wallet connections on 'undefined' is unacceptable.
      // if(!response.message.info){
      //   resetWalletConnection();
      // }
      setStatus(MINT_STATUS.TRANSACTION_FAILED)
    }
  }

  const Start = () => (
    <MintContainer>
      <h2>Select the number of Pigeons you want to mint</h2>
      {(walletParams && walletParams.orderData) && (
        walletParams.orderData.map((orderData) => (
          <PigeonNumber
            key={orderData.quantity}
            numberOfPigeons={orderData.quantity}
            priceIncFee={orderData.priceIncFee}
            onClick={() => handleTransaction({
              adaAmount: orderData.priceIncFee,
              amountOfTokens: orderData.quantity
            })}
          />
        ))
      )}
    </MintContainer>
  );

  const TransactionSuccess = () => {
    const delay = 30;

    const renderer = ({ seconds, completed }) => {
      if (completed) {
        return <div className="textupdate"><Button onClick={() => mintAnother()} className="link">Want to mint more? You can!</Button></div>;
      } else {
        return <div className="textupdate"><span>{`You can mint again in ${seconds} seconds!`}</span></div>;
      }
    };

    return (
    <MintContainer>
      <div className="thank-you-come-again">
        <img src={checkumarku} alt="Thank you!" />
        <span>Thank you!</span>
      </div>
      <Countdown renderer={renderer} date={Date.now() + (delay * 1000)}>
        
        </Countdown>
    </MintContainer>
  );
    }

  const TransactionStarted = () => (
    <MintContainer>
      <div className="textupdate">
        <span>{`We're connecting to your ${capitalize(selectedWallet)} wallet to continue the mint transaction there. Be patient, it might take a few seconds.`}</span>
      </div>
    </MintContainer>
  );

  const NotOnWhitelist = () => (
    <MintContainer>
      <div className="textupdate">
        <span>{`We're very sorry but you are not on our whitelist. You connected with address:`}<br/><br/>{`${walletAddress}.`}</span>
      </div>
    </MintContainer>
  );

  const TransactionFailed = () => (
    <MintContainer>
      <div className="textupdate">
        <span>{`Whoops, something went wrong trying to mint your pigeon. Please try again.`}</span>
      </div>
      <Button onClick={() => mintAnother()} className="link">Back to the mint!</Button>
    </MintContainer>
  );

  const getStep = (status) => {
    switch (status) {
      case MINT_STATUS.NOT_ON_WHITELIST:
        return <NotOnWhitelist />;
      case MINT_STATUS.START:
        return <Start />;
      case MINT_STATUS.TRANSACTION_STARTED:
        return <TransactionStarted />;
      case MINT_STATUS.TRANSACTION_FAILED:
        return <TransactionFailed />;
      case MINT_STATUS.TRANSACTION_SUCCESS:
        return <TransactionSuccess />;
      default:
        return <></>;
    }
  }

  return (
    <section className="mint">
      {getStep(status)}
    </section>
  );
};

export default Wallet;
