export const raritytable = {
  background: [
    { label: "Purplish", category: "Common" },
    { label: "Red", category: "Common" },
    { label: "Yellow", category: "Common" },
    { label: "Green", category: "Common" },
    { label: "Aqua", category: "Common" },
    { label: "Blue", category: "Common" },
    { label: "Navy", category: "Common" },
    { label: "Purple", category: "Common" },
    { label: "Pink", category: "Common" },
    { label: "Violet", category: "Common" },
    { label: "Stripes", category: "Uncommon" },
    { label: "Roadrunner", category: "Uncommon" },
    { label: "Desert", category: "Rare" },
    { label: "Island", category: "Epic" },
    { label: "Graveyard", category: "Legendary" },
  ],
  feet: [
    { label: "Yellow original", category: "Common" },
    { label: "Red", category: "Common" },
    { label: "Orange", category: "Common" },
    { label: "Pink", category: "Common" },
    { label: "Green", category: "Common" },
    { label: "Algae", category: "Common" },
    { label: "Blue", category: "Common" },
    { label: "Navy", category: "Common" },
    { label: "Midnight", category: "Common" },
    { label: "Violet", category: "Common" },
    { label: "Ring number 7", category: "Uncommon" },
    { label: "Ring with love", category: "Uncommon" },
    { label: "Ringnumber 69", category: "Uncommon" },
    { label: "Shoes", category: "Uncommon" },
    { label: "Kettlebell", category: "Uncommon" },
    { label: "Cyborg", category: "Uncommon" },
    { label: "Convict", category: "Rare" },
    { label: "Clown", category: "Rare" },
    { label: "Pencil", category: "Rare" },
    { label: "Sneakers", category: "Epic" },
    { label: "Bones", category: "Epic" },
    { label: "Pirate", category: "Legendary" },
  ],
  bodysuit: [
    { label: "Original", category: "Common" },
    { label: "Red", category: "Common" },
    { label: "Orange", category: "Common" },
    { label: "Pink", category: "Common" },
    { label: "Green", category: "Common" },
    { label: "Algae", category: "Common" },
    { label: "Blue", category: "Common" },
    { label: "Navy", category: "Common" },
    { label: "Midnight", category: "Common" },
    { label: "Violet", category: "Common" },
    { label: "Dungarees", category: "Uncommon" },
    { label: "Onesie", category: "Uncommon" },
    { label: "Ethereal", category: "Uncommon" },
    { label: "Cruijff", category: "Uncommon" },
    { label: "Mummy", category: "Rare" },
    { label: "Poison", category: "Rare" },
    { label: "Gothic", category: "Epic" },
    { label: "Nude", category: "Epic" },
    { label: "Steak", category: "Legendary" },
  ],
  eyes: [
    { label: "Yellow original", category: "Common" },
    { label: "Red", category: "Common" },
    { label: "Green", category: "Common" },
    { label: "Blue", category: "Common" },
    { label: "Pink", category: "Common" },
    { label: "Violet", category: "Common" },
    { label: "Big", category: "Common" },
    { label: "Giant", category: "Common" },
    { label: "Hypnotic", category: "Common" },
    { label: "Band aid", category: "Uncommon" },
    { label: "Glasses", category: "Uncommon" },
    { label: "Monocle", category: "Uncommon" },
    { label: "Robot", category: "Uncommon" },
    { label: "Sunglasses", category: "Rare" },
    { label: "Zombie", category: "Rare" },
    { label: "Star Trek", category: "Epic" },
    { label: "Eyepatch", category: "Epic" },
    { label: "Laser", category: "Legendary" },
  ],
  beak: [
    { label: "Yellow", category: "Common" },
    { label: "Orange", category: "Common" },
    { label: "Tongue", category: "Common" },
    { label: "Drip", category: "Common" },
    { label: "Teeth", category: "Common" },
    { label: "Curly kissing lips", category: "Common" },
    { label: "Curly tongue", category: "Uncommon" },
    { label: "Piercing", category: "Uncommon" },
    { label: "Pipe", category: "Uncommon" },
    { label: "Branch", category: "Uncommon" },
    { label: "Hotdog", category: "Rare" },
    { label: "Drinks", category: "Rare" },
    { label: "Peanut", category: "Rare" },
    { label: "Rope", category: "Epic" },
    { label: "Frisbee", category: "Epic" },
    { label: "Peace", category: "Legendary" },
  ],
  crown: [
    { label: "Hair original", category: "Common" },
    { label: "Ghost hair", category: "Common" },
    { label: "Two hairs", category: "Common" },
    { label: "Three hairs", category: "Common" },
    { label: "Bandana", category: "Common" },
    { label: "Gentleman", category: "Common" },
    { label: "Baseball cap", category: "Common" },
    { label: "Top hat", category: "Common" },
    { label: "Sailor", category: "Common" },
    { label: "Bald", category: "Common" },
    { label: "Mohawk", category: "Common" },
    { label: "Spikey mohawk", category: "Common" },
    { label: "Love", category: "Common" },
    { label: "Fork", category: "Common" },
    { label: "Bun", category: "Common" },
    { label: "Center", category: "Common" },
    { label: "Shelf", category: "Uncommon" },
    { label: "Aang avatar", category: "Uncommon" },
    { label: "Pile of poo", category: "Uncommon" },
    { label: "Worm", category: "Uncommon" },
    { label: "UFO", category: "Uncommon" },
    { label: "Baseball cap reversed", category: "Uncommon" },
    { label: "ToiletPlunger", category: "Uncommon" },
    { label: "Cork", category: "Uncommon" },
    { label: "Icecream", category: "Uncommon" },
    { label: "Baseball cap reversed XL", category: "Rare" },
    { label: "Egg", category: "Rare" },
    { label: "Fool", category: "Rare" },
    { label: "Unicorn", category: "Rare" },
    { label: "Shark", category: "Rare" },
    { label: "Mickey", category: "Rare" },
    { label: "Brains", category: "Epic" },
    { label: "Elvis", category: "Epic" },
    { label: "Cult halo", category: "Epic" },
    { label: "Samurai", category: "Epic" },
    { label: "Flowerpower", category: "Legendary" },
    { label: "Flames", category: "Legendary" },
  ],
  friends: [
    { label: "Wooden duckie", category: "Rare" },
    { label: "Masked bee", category: "Rare" },
    { label: "Crazy pumpkin", category: "Epic" },
    { label: "Weird things family", category: "Epic" },
    { label: "Ghost cousin", category: "Legendary" },
  ],
  special: [
    { label: "Ring right finger", category: "Common" },
    { label: "Ring left finger", category: "Common" },
    { label: "Balloons", category: "Common" },
    { label: "Yo-yo", category: "Common" },
    { label: "Dummy", category: "Common" },
    { label: "Cool rope figure", category: "Common" },
    { label: "Foam hand", category: "Common" },
    { label: "Lifebuoy", category: "Uncommon" },
    { label: "SG Circle", category: "Uncommon" },
    { label: "Piggy", category: "Uncommon" },
    { label: "Panda", category: "Uncommon" },
    { label: "Funny guy", category: "Uncommon" },
    { label: "SG Triangle", category: "Rare" },
    { label: "Chicken", category: "Rare" },
    { label: "BANG", category: "Rare" },
    { label: "SG Square", category: "Epic" },
    { label: "Frankie bolts", category: "Epic" },
    { label: "Sucking fish", category: "Legendary" },
  ],
};
