import React from "react"
import ProgressBar from "@ramonak/react-progress-bar"
import useFetch from "react-fetch-hook";

const ProgressBarFollowers = () => {
  const { isLoading, data } = useFetch("https://discord.com/api/guilds/906126750986166332/widget.json");
  const followers = !isLoading ? data?.channels?.find((channel) => channel?.id === "913134571552391258")?.name.split(':')[1]?.trim() : 0;

  return (
    <>
      <ProgressBar
        completed={`${followers}`}
        customLabel={`${followers}/5000`}
        maxCompleted={5000}
        bgColor="#36355C"
        baseBgColor="#ffffff"
        height="12px"
        borderRadius="20px"
        width="600px"
        className="progressbarroo desktop"
      />
      <ProgressBar
        completed={`${followers}`}
        customLabel={`${followers}/5000`}
        maxCompleted={5000}
        bgColor="#36355C"
        baseBgColor="#ffffff"
        height="12px"
        borderRadius="20px"
        width="60%"
        className="progressbarroo mobile"
      /></>
  )
}

export default ProgressBarFollowers;