import * as React from "react";
import "./index.scss";

import z from "../../images/team-ronald@2x.png";
import p from "../../images/team-peter@2x.png";
import tq from "../../images/team-tariq@2x.png";
import david from "../../images/team-david@2x.png";
import curvetop from "../../images/bg-meet-top.svg";
import curvebottom from "../../images/bg-meet-bottom.svg";
import logo from "../../images/logo-cult-of-pigeons@2x.png";
import logoDiscord from "../../images/logo-discord.svg";
import logoTwitter from "../../images/logo-twitter.svg";
import logoInstagram from "../../images/logo-instagram.svg";

const Cultist = ({ name, twitter, link, title, image }) => (
  <div className={`cultist ${name}`}>
    <img src={image} alt={name} />
    <h4>
      <a href={link}>{twitter}</a>
    </h4>
    <span>{title}</span>
  </div>
);

const Footer = ({ children, showTeam = true }) => {
  return (
    <footer id="team" className={showTeam ? 'team' : 'noteam'}>
      {showTeam && (
        <>
          <img
            className="curvalicious-topside-me-hearties-arr"
            src={curvetop}
            alt=""
          />
          <h2 className="mobile">Meet the cult</h2>
          <div className="cultists">
            <div className="row">
              <Cultist
                name="Z"
                twitter="@ronaldzwets"
                link="https://www.twitter.com/ronaldzwets"
                title="web cultist"
                image={z}
              />
            </div>
            <div className="row">
              <Cultist
                name="TQ"
                twitter="@RoninNFTX"
                link="https://www.twitter.com/RoninNFTX"
                title="blockchain cultist"
                image={tq}
              />
              <h2 className="desktop">Meet the Cult</h2>
              <Cultist
                name="Pinos"
                twitter="@pinos"
                link="https://dribbble.com/pinos"
                title="illustrator cultist"
                image={p}
              />
            </div>
            <div className="row">
              <Cultist
                name="David"
                twitter="@deejfit"
                link="https://www.twitter.com/deejfit"
                title="social guru"
                image={david}
              />
            </div>
          </div>
        </>)}
      <div className="footerlinks wrapper grid grid-cols-12 gap-2">
        <div className="logo col-start-2 col-span-10 lg:col-start-3 lg:col-span-2">
          <img src={logo} alt="Cult of Pigeons Logo" />
        </div>
        <div className="socials col-start-1 col-span-12 lg:col-start-7 lg:col-span-3">
          <div className="menu">
            <a href="https://discord.gg/w8Ef3XB5tm">
              <img src={logoDiscord} alt="Discord Logo" />
            </a>
            <a href="https://twitter.com/pigeonscultnft">
              <img src={logoTwitter} alt="Twitter Logo" />
            </a>
            <a href="https://www.instagram.com/pigeonscultnft/">
              <img src={logoInstagram} alt="Instagram Logo" />
            </a>
          </div>
          <span>{`Cult of Pigeons ${new Date().getFullYear()} © All rights reserved`}</span>
        </div>
      </div>
      <img className="ye-olde-bottom-dwelling-curve" src={curvebottom} alt="" />
    </footer >
  );
};

export default Footer;
