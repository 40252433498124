import * as React from "react";
import "./index.scss";

const Layout = ({ children, extraClass, fullWidth }) => {
  return (
    <div
      className={`section mx-auto ${fullWidth ? "" : "wrapper"}  ${extraClass}`}
    >
      {children}
    </div>
  );
};

export default Layout;
