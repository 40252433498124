import * as React from "react";
import "./index.scss";

const Button = ({ active, children, className, disabled, onClick }) => {
  return (
    <button
      onClick={onClick}
      className={`primary${active ? " active" : ""} ${className}`}
      disabled={disabled}
    >
      <span className="label">{children}</span>
    </button>
  );
};

export default Button;
