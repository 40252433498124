import * as React from "react";
import "./index.scss";

const RadioButton = ({ children, onClick, active }) => {
  return (
    <label className="radio radio-before">
      <span className="radio__input">
        <input
          type="radio"
          name="radio"
          data-active={active}
          onChange={onClick}
          checked={active}
        />
        <span className="radio__control"></span>
      </span>
      <span className="radio__label">{children}</span>
    </label>
  );
};

export default RadioButton;
