import React from "react";
import "./index.scss";

import adalogo from "../../images/cardano-ada-logo.svg";

const PigeonNumber = ({ numberOfPigeons, priceIncFee, onClick }) => {
  return (
    <div className={`pigeonNumber`} onClick={onClick}>
      <div className="content">
        <div className="row">
          <h2>{numberOfPigeons} Pigeon</h2>
          <p>Including transaction fee</p>
        </div>
        <div className="row">
          <h3>{priceIncFee} ADA</h3>
        </div>
      </div>
      <figure><img src={adalogo} alt="Cardano Logo" /></figure>
    </div>
  );
};

export default PigeonNumber;
