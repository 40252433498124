import * as React from "react";
import "./index.scss";

import topImg from "../../images/cops-flow@2x.png";

// eslint-disable-next-line react/prop-types
const MintContainer = ({ children }) => {
  return (
    <div className="mintwrapper relative">
      <div className="mintcontainer">
        <div className="mint-top">
          <img src={topImg} alt="Mint-a-Pigeon" />
        </div>
        <div className="mint-content">
          {children}
        </div>
      </div>
    </div>
  );
};

export default MintContainer;
