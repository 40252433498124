
import { WalletApi } from 'cardano-wallet-api';

import * as WASM_LIB from '@emurgo/cardano-serialization-lib-asmjs';
import { postReservation } from "./apiConnector";

const responses = {
  success: {
    status: 'success',
    message: 'connected'
  },
  failed: {
    status: 'failed',
    message: 'failed'
  }
}

const doConnect = async (protocolParams, walletType) => {
  try {
    const wallet = await WalletApi(
      window.cardano,
      protocolParams,
      walletType,
      WASM_LIB
    );

    await wallet.enable();

    return {
      response: { ...responses.success },
      wallet
    };
  } catch (error) {
    return { ...responses.failed, message: error };
  }
}

const doTransaction = async ({ walletAddress, adaAmount, amountOfTokens, wallet }) => {
  try {
    await wallet.enable()

    const txHash = await wallet.send({
      address: walletAddress,
      amount: adaAmount,
    });

    if (txHash) {
      const response = await postReservation(amountOfTokens, txHash);

      if(response.status !== 200) {
        return {
          ...responses.failed,
          message: response.error
        }
      }

      return {
        ...responses.success,
        message: {
          amountOfTokens,
          txHash,
          response
        }
      }
    }

    return {
      ...responses.failed,
      message: txHash
    }
  } catch (error) {
    return { ...responses.failed, message: error };
  }
}

export { doConnect, doTransaction }
