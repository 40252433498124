import * as React from "react";
import "./index.scss";
import { Countdown } from "../";

import { features } from "../../config/features";

import pigeon from "../../images/the-original-cop@2x.png";
import bgo from "../../images/bg-o.svg";
import bgcurve from "../../images/bg-curve.svg";

const Hero = () => {
  return (
    <div className={`hero relative ${features.mintButton && 'mb-0'}`}>
      <img className="bg-o-wood" src={bgo} alt="" />
      <img className="bg-curve" src={bgcurve} alt="" />
      <img className="bg-o-pigeon" src={bgo} alt="" />
      <div className="pigeon">
        <img id="ogpigeon" src={pigeon} alt="The Cult of Pigeons Pigeon" />
        {features.countdown && <Countdown />}
      </div>
    </div >
  );
};

export default Hero;
