import * as React from "react";
import "./index.scss";

import logo from "../../images/logo-cult-of-pigeons@2x.png";

const Navigation = () => (
  <div className="topnav">
    <nav className="grid grid-cols-12 gap-2">
      <div className="logo col-start-1 col-span-12 lg:col-start-3 lg:col-span-2">
        <img src={logo} alt="Cult of Pigeons Logo" />
      </div>
    </nav>
  </div>
);

export default Navigation;
