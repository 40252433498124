import React, { useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import Select, { components } from "react-select";
import Button from "../Button";

import { raritytable } from "./raritylib";

import "./index.scss";

import arrowLeft from "../../images/arrowleft.svg";
import arrowRight from "../../images/arrowright.svg";
import dropdownIndicator from "../../images/dropdownindicator.svg";
import { RadioButton } from "..";

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 1,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const types = [
  { value: "background", label: "Background" },
  { value: "beak", label: "Beak" },
  { value: "bodysuit", label: "Bodysuit" },
  { value: "crown", label: "Crown" },
  { value: "eyes", label: "Eyes" },
  { value: "feet", label: "Feet" },
  { value: "friends", label: "Friends" },
  { value: "special", label: "Specials" },
];

const disabledFilters = [];

const rarity = [
  { value: "All", label: "All" },
  { value: "Common", label: "Common" },
  { value: "Uncommon", label: "Uncommon" },
  { value: "Rare", label: "Rare" },
  { value: "Epic", label: "Epic" },
  { value: "Legendary", label: "Legendary" },
];

const getTraitImages = (prefix, count) => {
  const rarities = raritytable[prefix];
  const images = [];
  for (let i = 1; i <= count; i++) {
    images.push({
      url: `/traits/traits-${prefix}/traits-${prefix}-${i
        .toString()
        .padStart(2, "0")}.png`,
      title: rarities ? rarities[i - 1].label : "",
      category: rarities ? rarities[i - 1].category : "",
      prefix,
    });
  }

  return images;
};

const getAllTraitImages = () => {
  const traits = [
    { prefix: "background", count: 15 },
    { prefix: "beak", count: 16 },
    { prefix: "bodysuit", count: 19 },
    { prefix: "crown", count: 37 },
    { prefix: "eyes", count: 18 },
    { prefix: "feet", count: 22 },
    { prefix: "friends", count: 5 },
    { prefix: "special", count: 18 },
  ];
  const allTraitImages = traits.map(({ prefix, count }) =>
    getTraitImages(prefix, count)
  );

  return allTraitImages.flat();
};

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <img src={dropdownIndicator} alt="" />
    </components.DropdownIndicator>
  );
};

const CustomRightArrow = ({ onClick, ...rest }) => {
  const {
    onMove,
    carouselState: { currentSlide, deviceType },
  } = rest;
  return (
    <button className="arrow-right" onClick={() => onClick()}>
      <img src={arrowRight} alt="" />
    </button>
  );
};

const CustomLeftArrow = ({ onClick, ...rest }) => {
  const {
    onMove,
    carouselState: { currentSlide, deviceType },
  } = rest;
  return (
    <button className="arrow-left" onClick={() => onClick()}>
      <img src={arrowLeft} alt="" />
    </button>
  );
};

const CarouselItem = ({ children, subtext, image }) => (
  <div className="carouselItem">
    <div className="portrait">
      <img src={image} alt="" />
    </div>
    <div className="itemContent">
      <h3>{children}</h3>
      <p>{subtext}</p>
    </div>
  </div>
);

const getCarouselItems = (typeFilters, rarityFilters) => {
  const traitImages = getAllTraitImages();

  let filteredTraitImages = [...traitImages];
  if (typeFilters && typeFilters.length > 0) {
    filteredTraitImages = traitImages.filter((traitimage) =>
      typeFilters.includes(traitimage.prefix)
    );
  }

  if (rarityFilters && rarityFilters.length > 0 && rarityFilters[0] !== "all") {
    filteredTraitImages = filteredTraitImages.filter((traitimage) =>
      rarityFilters.includes(traitimage.category.toLowerCase())
    );
  }

  return filteredTraitImages.map((image) => (
    <CarouselItem key={image.url} image={image.url} subtext={image.category}>
      {image.title}
    </CarouselItem>
  ));
};

const getCarousel = (typeFilters, rarityFilters) => (
  <Carousel
    key={`carousel-${typeFilters.join("")}-${rarityFilters.join("")}`}
    responsive={responsive}
    customLeftArrow={<CustomLeftArrow />}
    customRightArrow={<CustomRightArrow />}
  >
    {getCarouselItems(typeFilters, rarityFilters)}
  </Carousel>
);

const RarityBrowser = ({ children }) => {
  const [typeFilters, setTypeFilters] = useState(["background"]);
  const [rarityFilters, setRarityFilters] = useState(["all"]);

  const toggleType = ({ value }) => {
    // reset rarity to all
    setRarityFilters(["all"]);

    const type = value.toLowerCase();
    let arr = [...typeFilters];
    if (typeFilters.includes(type)) {
      arr = typeFilters.filter((e) => e !== type);
    } else {
      // arr.push(type); for multiple
      arr = [type]; // for single
    }
    setTypeFilters(arr);
  };

  const toggleRarity = ({ value }) => {
    const rar = value.toLowerCase();
    let arr = [...rarityFilters];
    if (rarityFilters.includes(rar)) {
      arr = rarityFilters.filter((e) => e !== rar);
    } else {
      // arr.push(type); for multiple
      arr = [rar]; // for single
    }

    setRarityFilters(arr);
  };

  return (
    <div className="raritybrowser grid grid-cols-12 gap-2 relative" id="traits">
      <h1 className="col-start-3 col-span-8 w-full text-center">
        Traits and Rarity
      </h1>
      <div className="filters col-start-1 col-span-12">
        <div className="typefilters">
          {types.map((type) => (
            <Button
              key={type.value}
              active={
                typeFilters.includes(type.value) ||
                (type.value === "" && typeFilters.length === 0)
              }
              onClick={() => toggleType(type)}
              disabled={disabledFilters.includes(type.value)}
            >
              {type.label}
            </Button>
          ))}
        </div>
        <div className="rarityfilters col-start-3 col-span-8">
          {rarity.map((rar) => {
            if (
              typeFilters.includes("friends") &&
              (rar.label === "Common" || rar.label === "Uncommon")
            ) {
              return;
            }
            return (
              <RadioButton
                key={rar.label}
                onClick={() => toggleRarity(rar)}
                active={rarityFilters.includes(rar.label.toLowerCase())}
              >
                {rar.label}
              </RadioButton>
            );
          })}
        </div>
      </div>
      <div className="mobileFilters col-start-3 col-span-8">
        <Select
          className="basic-single"
          classNamePrefix="select"
          components={{ DropdownIndicator }}
          defaultValue={types[0]}
          isDisabled={false}
          isLoading={false}
          isClearable={false}
          isRtl={false}
          isSearchable={false}
          name="types"
          options={types}
          onChange={toggleType}
        />
        <Select
          key={`rarityfilter-${typeFilters.join("")}}`}
          className="basic-single"
          classNamePrefix="select"
          components={{ DropdownIndicator }}
          defaultValue={rarity[0]}
          isDisabled={false}
          isLoading={false}
          isClearable={false}
          isRtl={false}
          isSearchable={false}
          name="rarity"
          options={rarity.filter((rar) => {
            if (
              typeFilters.includes("friends") &&
              (rar.label === "Common" || rar.label === "Uncommon")
            ) {
              return false;
            }
            return true;
          })}
          onChange={toggleRarity}
        />
      </div>
      <div className="galleria col-start-1 col-span-12">
        <div className="carousel">
          {getCarousel(typeFilters, rarityFilters)}
        </div>
      </div>
    </div>
  );
};

export default RarityBrowser;
