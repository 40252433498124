import React, { useState } from "react";
import "./index.scss";
import { Countdown } from "../";
import { Button } from "../index";

import topImg from "../../images/cops-flow@2x.png";
import PigeonNumber from "../PigeonNumber";

const NumberStep = ({ number, children }) => (
  <div className="numberstep">
    <span className="number">{number}</span>
    <span className="content">{children}</span>
  </div>
);

const MintStep2 = ({ children }) => {
  const [selected, setSelected] = useState(0);

  return (
    <div className="mintstep">
      <h2>Select the number of COPS</h2>
      <PigeonNumber numberOfPigeons={1} onClick={() => setSelected(1)} selected={selected === 1} />
      <PigeonNumber numberOfPigeons={2} onClick={() => setSelected(2)} selected={selected === 2} />
      <PigeonNumber numberOfPigeons={3} onClick={() => setSelected(3)} selected={selected === 3} />
      <Button>Step 2</Button>
    </div>
  );
};

export default MintStep2;
