import React, {useState, useEffect} from "react";
import ScrollIndicator from "react-scroll-sensor";
import { WalletType } from 'cardano-wallet-api';

import { features } from "../../config/features";

import "./index.scss";

import logoBurg0r from "../../images/ic.svg";
import logoCross from "../../images/ic-close.svg";
import logoDiscord from "../../images/logo-discord.svg";
import logoTwitter from "../../images/logo-twitter.svg";
import logoInstagram from "../../images/logo-instagram.svg";
import namilogo from "../../images/nami-logo.svg";
import ccvaultlogo from "../../images/ccvault-logo.svg";
import { Button } from "..";

const getDeviceType = () => {
  const ua = navigator.userAgent;
  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
      return "tablet";
  }
  else if (/Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)) {
      return "mobile";
  }
  return "desktop";
};

const BurgerBoi = ({ open, toggle, handleConnect, walletConnected, selectedWallet }) => {
  const [isDesktop, setIsDesktop] = useState(false);

  useEffect(() => {
    const deviceType = getDeviceType();
    if(deviceType === 'desktop'){
      setIsDesktop(true);
    }
  })


  const logos = {
    [WalletType.NAMI]: namilogo,
    [WalletType.CCVAULT]: ccvaultlogo
  };

  return (
    <div className={`burgerboi wrapper mx-auto${open ? " open" : ""}`}>
      <div className="grid grid-cols-12 gap-2">
        <div className="lg:col-start-5 lg:col-span-4 col-start-1 col-span-12 walletmenuwrapper">
          {(features.mintButton && isDesktop && !open) && (
            <ScrollIndicator throttleDelay={1}>
              {(scrollX, scrollY) => (
                <div
                  className={`${scrollY > 86 ? "hidden" : ""} ${scrollY > 476 ? "gone" : ""
                    } walletmenu`}
                >
                  <Button className={`connectbutton small ${walletConnected && 'online'}`} disabled={walletConnected}>
                    {selectedWallet && <img className="logo" src={logos[selectedWallet]} />}
                    <span>{walletConnected ? 'Wallet connected!' : 'Connect wallet'}</span>
                  </Button>
                  <div className="connectmenu">
                    <ul className="walletlist">
                      <li onClick={() => handleConnect(WalletType.NAMI)}>
                        <img src={namilogo} />Nami
                      </li>
                      <li onClick={() => handleConnect(WalletType.CCVAULT)}>
                        <img src={ccvaultlogo} />ccvault
                      </li>
                    </ul>
                  </div>
                </div>
              )}
            </ScrollIndicator>
          )}
        </div>
        <div className="lg:col-start-9 lg:col-span-2 col-start-1 col-span-12">
          <div className="burgerflex">
            <ScrollIndicator throttleDelay={1}>
              {(scrollX, scrollY) => (
                <div
                  className={`${scrollY > 86 ? "hidden" : ""} ${scrollY > 476 ? "gone" : ""
                    } socials`}
                >
                  <a href="https://discord.gg/w8Ef3XB5tm">
                    <img src={logoDiscord} alt="Discord Logo" />
                  </a>
                  <a href="https://twitter.com/pigeonscultnft">
                    <img src={logoTwitter} alt="Twitter Logo" />
                  </a>
                  <a href="https://www.instagram.com/pigeonscultnft/">
                    <img src={logoInstagram} alt="Instagram Logo" />
                  </a>
                </div>
              )}
            </ScrollIndicator>

            <button onClick={toggle} className="hamburger">
              <img src={open ? logoCross : logoBurg0r} alt="Hamburger menu" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BurgerBoi;
