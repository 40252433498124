import * as React from "react";
import "./index.scss";

import wood from "../../images/wood.png";

const Countdown = ({ extraClass }) => {
  return (
    <div className={`countdown ${extraClass || ""}`}>
      <img src={wood} alt="Woody Countdown Clock" />
      <span className="shadowtext">Public mint paused<br/>Coming back soon</span>
      <span>Public mint paused<br/>Coming back soon</span>
    </div>

  );
};

export default Countdown;
